export const printersItem = [
    {
      src: "/img/All sun light/Printers/1.webp",
      title: "binding machine",
    },
    {
      src: "/img/All sun light/Printers/2.webp",
      title: "Calculators",
    },
    {
      src: "/img/All sun light/Printers/3.webp",
      title: "Counting Machine",
    },
    {
      src: "/img/All sun light/Printers/4.webp",
      title: "Fire Proof Filling Cabinet",
    },
    {
      src: "/img/All sun light/Printers/5.webp",
      title: "Laminator Machine",
    },
    {
      src: "/img/All sun light/Printers/6.webp",
      title: "Metal Mesh Paper Tray",
    },
    {
      src: "/img/All sun light/Printers/7.webp",
      title: "Plastic Paper Tray",
    },
    {
      src: "/img/All sun light/Printers/8.webp",
      title: "Printer Cartridge",
    },
    {
      src: "/img/All sun light/Printers/9.webp",
      title: "Printing Calculators",
    },
    {
      src: "/img/All sun light/Printers/10.webp",
      title: "Safety Boxes Fire Proof",
    },
    {
      src: "/img/All sun light/Printers/11.webp",
      title: "Shredder Machine",
    },
    {
      src: "/img/All sun light/Printers/12.webp",
      title: "Trimmers & Guillotines",
    },
]