export const HygieneItem = [
    {
      src: "/img/All sun light/Hygiene/1.jpg",
      title: "Apron PE white",
    },
    {
      src: "/img/All sun light/Hygiene/2.jpg",
      title: "Disposable Face Mask",
    },
    {
      src: "/img/All sun light/Hygiene/3.jpg",
      title: "Hairnet Blue/Black/white",
    },
    {
      src: "/img/All sun light/Hygiene/4.jpg",
      title: "Latex Gloves powder free",
    },
    {
      src: "/img/All sun light/Hygiene/5.jpg",
      title: "Nitrile Gloves pack",
    },
    {
      src: "/img/All sun light/Hygiene/6.jpg",
      title: "Vinyl Gloves clear/black",
    },
    {
      src: "/img/All sun light/Hygiene/7.jpg",
      title: "Shoe cover non woven",
    },
    {
      src: "/img/All sun light/Hygiene/8.jpg",
      title: "non woven beared mask ",
    },
    {
      src: "/img/All sun light/Hygiene/9.jpg",
      title: " polethelene elbow length gloves",
    },
    {
      src: "/img/All sun light/Hygiene/10.jpg",
      title: " cut resistant gloves cut safe coated",
    },
    {
      src: "/img/All sun light/Hygiene/11.jpg",
      title: " polyethelene visitor coat",
    },
    {
      src: "/img/All sun light/Hygiene/12.jpg",
      title: " non woven visitor coat",
    },
    {
      src: "/img/All sun light/Hygiene/13.jpg",
      title: " nonwoven coverall with hood",
    },
]