export const GarbageItem = [
    {
      src: "/img/All sun light/garbage/1.webp",
      title: "Ashtray Bin Square black",
    },
    {
      src: "/img/All sun light/garbage/2.webp",
      title: "Ashtray wooder bin ",
    },
    {
      src: "/img/All sun light/garbage/3.webp",
      title: "SS Swing Type Bin",
    },
    {
      src: "/img/images/214.webp",
      title: "Swing Dustbin plastic type",
    },
    {
      src: "/img/All sun light/garbage/5.webp",
      title: "Garbage Bag Black Recyle",
    },
    {
      src: "/img/All sun light/garbage/6.webp",
      title: "Garbage Bag Black HD",
    },
    {
      src: "/img/All sun light/garbage/7.webp",
      title: "Garbage Bag white HD",
    },
    {
      src: "/img/All sun light/garbage/8.webp",
      title: "Garbage Bin Single Door with Pedal& wheels",
    },
    {
      src: "/img/All sun light/garbage/9.webp",
      title: "Garbage Bin with Pedal",
    },
    {
      src: "/img/All sun light/garbage/10.webp",
      title: "Garbage Bin without pedal ",
    },
    {
      src: "/img/All sun light/garbage/11.webp",
      title: "SS Ashtray bin",
    },
    {
      src: "/img/All sun light/garbage/12.webp",
      title: "SS Bin with Pedal",
    },
]