export const ProjectList = [
  {
    url: "/project-details",
    src: "/img/All sun light/1.jpg",
    src2: "/img/All sun light/1.jpg",
    title: "Outdoor Cleaning",
  },
  {
    url: "/project-details",
    src: "/img/project/project_img02.jpg",
    src2: "/img/project/h4_project_img02.jpg",
    title: "Furniture Cleaning",
  },
  {
    url: "/project-details",
    src: "/img/project/project_img03.jpg",
    src2: "/img/project/h4_project_img03.jpg",
    title: "Kitchen Cleaning",
  },
  {
    url: "/project-details",
    src: "/img/project/project_img04.jpg",
    src2: "/img/project/h4_project_img04.jpg",
    title: "Car Cleaning",
  },
  {
    url: "/project-details",
    src: "/img/project/project_img05.jpg",
    src2: "/img/project/h4_project_img05.jpg",
    title: "Plumbing Service",
  },
  {
    url: "/project-details",
    src: "/img/project/project_img06.jpg",
    src2: "/img/project/h4_project_img06.jpg",
    title: "Kitchen Cleaning",
  },
];
