export const BlogItemsArray = [
  {
    src: "/img/blog/blog_img01.jpg",
    src2: "/img/blog/h2_blog_img01.jpg",
    src3: "/img/blog/inner_blog_img01.jpg",
    url: "/blog-details",
    tag: "Kitchen",
    created_at: "Jun 4, 2022",
    author: "David Martin",
    title: "New Cleaning With Hydrogen at Peroxide Tips",
    desc: `Lorem ipsum dolor sit amet consectetur. Ut tellus suspendisse nulla
    aliquam. Risus rutrum tellus as eget ultrices amet facilisis.`,
  },
  {
    src: "/img/blog/blog_img02.jpg",
    src2: "/img/blog/h2_blog_img02.jpg",
    src3: "/img/blog/inner_blog_img02.jpg",
    url: "/blog-details",
    tag: "Office",
    created_at: "Jun 4, 2022",
    author: "David Martin",
    title: "Apartment Therapy Wisdom Taking Care",
    desc: `Lorem ipsum dolor sit amet consectetur. Ut tellus suspendisse nulla
    aliquam. Risus rutrum tellus as eget ultrices amet facilisis.`,
  },
  {
    src: "/img/blog/blog_img03.jpg",
    src2: "/img/blog/h2_blog_img03.jpg",
    src3: "/img/blog/inner_blog_img03.jpg",
    url: "/blog-details",
    tag: "Home",
    created_at: "Jun 4, 2022",
    author: "David Martin",
    title: "The “Flip and Fluff” Routine is the Best Thing",
    desc: `Lorem ipsum dolor sit amet consectetur. Ut tellus suspendisse nulla
    aliquam. Risus rutrum tellus as eget ultrices amet facilisis.`,
  },
  {
    src: "/img/blog/blog_img04.jpg",
    src2: "/img/blog/h2_blog_img04.jpg",
    src3: "/img/blog/inner_blog_img04.jpg",
    url: "/blog-details",
    tag: "Home",
    created_at: "Jun 4, 2022",
    author: "David Martin",
    title: "New Cleaning With Hydrogen at many Peroxide Tips",
    desc: `Lorem ipsum dolor sit amet consectetur. Ut tellus suspendisse nulla
    aliquam. Risus rutrum tellus as eget ultrices amet facilisis.`,
  },
];
