export const DispenserItem = [
    {
      src: "/img/All sun light/Dispenser/1.webp",
      title: "Maxiroll Autocut 2ply",
    },
    {
      src: "/img/All sun light/Dispenser/2.webp",
      title: "Autocut Dispenser Manual",
    },
    {
      src: "/img/All sun light/Dispenser/3.webp",
      title: "Autocut Hand Towel Dispenser Automatic",
    },
    {
      src: "/img/All sun light/Dispenser/4.webp",
      title: "Automatic Airfreshner dispenser",
    },
    {
      src: "/img/All sun light/Dispenser/5.webp",
      title: "Air Diffuser",
    },
    {
      src: "/img/All sun light/Dispenser/6.webp",
      title: "Jet Hand Dryer",
    },
    {
      src: "/img/All sun light/Dispenser/7.webp",
      title: "Bed Roll ",
    },
    {
      src: "/img/All sun light/Dispenser/8.webp",
      title: "Interfold Tissue ",
    },
    {
      src: "/img/All sun light/Dispenser/9.webp",
      title: "Interfold Tissue Dispenser",
    },
    {
      src: "/img/All sun light/Dispenser/10.webp",
      title: "Facial Tissue 100x2ply ",
    },
    {
      src: "/img/All sun light/Dispenser/11.webp",
      title: "Facial Tissue 150x2ply",
    },
    {
      src: "/img/All sun light/Dispenser/12.webp",
      title: "Facial Tissue 200x2ply",
    },
    {
      src: "/img/All sun light/Dispenser/13.webp",
      title: "maxiroll 2ply ",
    },
    {
      src: "/img/All sun light/Dispenser/14.webp",
      title: "Maxiroll Dispenser",
    },
    {
      src: "/img/All sun light/Dispenser/15.webp",
      title: "Toilet Roll 300sheets",
    },
    {
      src: "/img/All sun light/Dispenser/16.webp",
      title: "Fine Toilet roll 400 sheet",
    },
    {
      src: "/img/All sun light/Dispenser/17.webp",
      title: "Hand Dryer SS/ Metal ",
    },
    {
      src: "/img/All sun light/Dispenser/18.webp",
      title: "Liquid Soap Dispenser",
    },
    {
      src: "/img/All sun light/Dispenser/19.webp",
      title: "Toilet Roll Dispenser",
    },
]