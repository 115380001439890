export const DisposablelItem = [
    {
      src: "/img/All sun light/Disposablel/1.webp",
      title: "paper cup ",
    },
    {
      src: "/img/All sun light/Disposablel/2.webp",
      title: "plastic glass",
    },
    {
      src: "/img/All sun light/Disposablel/3.webp",
      title: "Foam Glass",
    },
    {
      src: "/img/All sun light/Disposablel/4.webp",
      title: "Ripple Paper cup",
    },
    {
      src: "/img/All sun light/Disposablel/5.webp",
      title: "Plastic curry bowls",
    },
    {
      src: "/img/All sun light/Disposablel/6.webp",
      title: "Aluminium container",
    },
    {
      src: "/img/All sun light/Disposablel/7.webp",
      title: "Aluminium foil ",
    },
    {
      src: "/img/All sun light/Disposablel/8.webp",
      title: "Cling Film",
    },
    {
      src: "/img/All sun light/Disposablel/9.webp",
      title: "Stretch film",
    },
    {
      src: "/img/All sun light/Disposablel/10.webp",
      title: "Plastic table spoon ",
    },
    {
      src: "/img/All sun light/Disposablel/11.webp",
      title: "Chafing Fuel",
    },
    {
      src: "/img/All sun light/Disposablel/12.webp",
      title: "Foam Plates",
    },
    {
      src: "/img/All sun light/Disposablel/13.webp",
      title: "Plastic Plates",
    },
    {
      src: "/img/All sun light/Disposablel/14.webp",
      title: "paper Plates ",
    },
    {
      src: "/img/All sun light/Disposablel/15.webp",
      title: "Soup bowl kraft Paper",
    },
    {
      src: "/img/All sun light/Disposablel/16.webp",
      title: "Microwavable food container",
    },
    {
      src: "/img/All sun light/Disposablel/17.webp",
      title: "Clear Duct Tape 2",
    },
    {
      src: "/img/All sun light/Disposablel/18.webp",
      title: "RC Bag Clear, color",
    },
    {
      src: "/img/All sun light/Disposablel/19.webp",
      title: "Cafetria Bag printed",
    },
]