export const CleaningItem = [
    {
      src: "/img/All sun light/cleaning/1.webp",
      title: "Laundry cart long metal",
    },
    {
      src: "/img/All sun light/cleaning/2.webp",
      title: "Airport Mop Sleeve and frame",
    },
   
    {
      src: "/img/All sun light/cleaning/3.webp",
      title: "Antares 1 Security Top Evolution Mega Italy",
    },
   
    {
      src: "/img/All sun light/cleaning/4.webp",
      title: "Basin Brush",
    },
   
    {
      src: "/img/All sun light/cleaning/5.webp",
      title: "Coco Brush with plain wooden handle",
    },
   
    {
      src: "/img/All sun light/cleaning/6.webp",
      title: "Double bucket trolley with steel frame",
    },
   
    {
      src: "/img/All sun light/cleaning/7.webp",
      title: "Double bucket trolley with plastic frame",
    },
   
    {
      src: "/img/All sun light/cleaning/8.webp",
      title: "DustPan Set",
    },
   
    {
      src: "/img/All sun light/cleaning/9.webp",
      title: "Feather Duster ",
    },
   
    {
      src: "/img/All sun light/cleaning/10.webp",
      title: "Floor Wiper",
    },
   
    {
      src: "/img/All sun light/cleaning/11.webp",
      title: "Glass Applicator Handle & Sleeve ",
    },
   
    {
      src: "/img/All sun light/cleaning/12.webp",
      title: "Glass cleaning bucket",
    },
   
    {
      src: "/img/All sun light/cleaning/13.webp",
      title: "Glass wiper",
    },
   
    {
      src: "/img/All sun light/cleaning/14.webp",
      title: "Hard & Soft broom brush",
    },
   
    {
      src: "/img/All sun light/cleaning/15.webp",
      title: "House keeping cart wooden Body",
    },
   
    {
      src: "/img/All sun light/cleaning/16.webp",
      title: "House keeping cart steel body",
    },
   
    {
      src: "/img/All sun light/cleaning/17.webp",
      title: "Floor Mop",
    },
   
    {
      src: "/img/All sun light/cleaning/18.webp",
      title: "Microfiber floor mop",
    },
   
    {
      src: "/img/All sun light/cleaning/19.webp",
      title: "Kitchen towel small & Big",
    },
   
    {
      src: "/img/All sun light/cleaning/20.webp",
      title: "microfibre cloth towel",
    },
   
    {
      src: "/img/All sun light/cleaning/21.webp",
      title: "Microfibre Long duster ",
    },
   
    {
      src: "/img/All sun light/cleaning/22.webp",
      title: "Microfibre dust mop frame & sleeve",
    },
   
    {
      src: "/img/All sun light/cleaning/23.webp",
      title: "Mini wringler trolley yellow",
    },
   
    {
      src: "/img/All sun light/cleaning/24.webp",
      title: "Mop Holder clips",
    },
   
    {
      src: "/img/All sun light/cleaning/25.webp",
      title: "Niper",
    },
   
    {
      src: "/img/All sun light/cleaning/26.webp",
      title: "Platform trolley",
    },
   
    {
      src: "/img/All sun light/cleaning/27.webp",
      title: "Sponge ",
    },
   
    {
      src: "/img/All sun light/cleaning/28.webp",
      title: "Scotch Brite stainless steel spiral",
    },
   
    {
      src: "/img/All sun light/cleaning/29.webp",
      title: "Single Bucket Trolley ",
    },
   
    {
      src: "/img/All sun light/cleaning/30.webp",
      title: "Spray mop Full set",
    },
   
    {
      src: "/img/All sun light/cleaning/31.webp",
      title: "WC /Toilet Brush with round holder",
    },
   
    {
      src: "/img/All sun light/cleaning/32.webp",
      title: "toilet Pump Normal/Heavy duty",
    },
    {
      src: "/img/All sun light/cleaning/33.webp",
      title: "Lobby dustpan set closed",
    },
    {
      src: "/img/All sun light/cleaning/34.webp",
      title: "ong dustpan set with long handle",
    },
   
    
    
  ];
  